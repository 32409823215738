import { Logger } from '~/helpers/logger'
import { BillingCartAddress, Maybe, SetBillingAddressOnCartInput } from '~/modules/GraphQL/types'
import { throwGqlError } from '~/helpers/throwGqlError'

export const saveBillingAddressCommand = {
  execute: async (context, cartId, billingDetails, customQuery): Promise<Maybe<BillingCartAddress>> => {
    const {
      apartment,
      neighborhood,
      extra,
      sameAsShipping,
      customerAddressId,
      ...address
    } = billingDetails

    const billingData = customerAddressId
      ? ({ customer_address_id: customerAddressId })
      : ({
          address: {
            ...address,
            street: [address.street, apartment, neighborhood, extra].filter(Boolean)
          },
          same_as_shipping: sameAsShipping
        })

    const setBillingAddressOnCartInput: SetBillingAddressOnCartInput = {
      cart_id: cartId,
      billing_address: billingData
    }

    const res = await context.$vsf.$magento.api.setBillingAddressOnCart(
      setBillingAddressOnCartInput,
      customQuery
    )
    const { data } = res
    Logger.debug('[Result]:', { data })
    throwGqlError(res)

    return data?.setBillingAddressOnCart?.cart?.billing_address ?? null
  }
}

import { CustomQuery, UseContextReturn } from '~/types/core'
import type { Cart, SetShippingMethodsOnCartInput } from '~/modules/GraphQL/types'
import { UseShippingProviderError } from '~/modules/checkout/composables/useShippingProvider'
import { throwGqlError } from '~/helpers/throwGqlError'

export const setShippingMethodsOnCartCommand = {
  execute: async (context: UseContextReturn, shippingMethodParams: SetShippingMethodsOnCartInput, customQuery: CustomQuery): Promise<Cart | UseShippingProviderError | null> => {
    const res = await context.app.$vsf.$magento.api.setShippingMethodsOnCart(shippingMethodParams, customQuery)
    throwGqlError(res)
    const { data } = res

    return (data?.setShippingMethodsOnCart?.cart as unknown as Cart) ?? null
  }
}

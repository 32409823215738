import { defineStore } from 'pinia'
import { Country } from '~/modules/GraphQL/types'

interface CountrySearchStore {
  countries: Country[]
  countryDetails: {
    [key: string]: Country
  }
}

export const useCountrySearchStore = defineStore('countriesStore', {
  state: (): CountrySearchStore => ({
    countries: [],
    countryDetails: {}
  })
})

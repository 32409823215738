import { Ref, ref, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import {
  UseShippingProviderErrors,
  UseShippingProviderInterface,
  UseShippingProviderLoadParams,
  UseShippingProviderSaveParams
} from './useShippingProvider'
import { Logger } from '~/helpers/logger'
import { useCart } from '~/modules/checkout/composables/useCart'
import { setShippingMethodsOnCartCommand } from '~/modules/checkout/composables/useShippingProvider/commands/setShippingMethodsOnCartCommand'
import { SetShippingMethodsOnCartInput, SelectedShippingMethod, Cart } from '~/modules/GraphQL/types'
import { useLoadingStore } from '~/modules/checkout/aatrium/stores/loadingStore'
import { CustomQuery } from '~/types/core'

/**
 * Allows loading the shipping provider
 * for the current cart and saving (selecting) other shipping provider for the
 * same cart.
 *
 * See the {@link UseShippingProviderInterface} for a list of methods and values available in this composable.
 */
export function useShippingProvider (): UseShippingProviderInterface {
  const loadingStore = useLoadingStore()
  const { loadingShippingProvider: loading } = storeToRefs(loadingStore)
  const error = ref<UseShippingProviderErrors>({
    load: null,
    save: null
  })
  const { cart, setCart, load: loadCart } = useCart()
  const context = useContext()
  const defaultCustomQuery: CustomQuery = {
    setShippingMethodsOnCart: 'setShippingMethodsOnCart'
  }

  async function save ({ shippingMethod, customQuery = defaultCustomQuery }: UseShippingProviderSaveParams): Promise<SelectedShippingMethod | null> {
    Logger.debug('useShippingProvider.save')
    let result = null
    try {
      loading.value = true

      const shippingMethodParams: SetShippingMethodsOnCartInput = {
        cart_id: cart.value.id,
        shipping_methods: [shippingMethod]
      }

      const cartData = await setShippingMethodsOnCartCommand.execute(context, shippingMethodParams, customQuery)
      Logger.debug('[Result]:', { cartData })
      setCart(cartData as Cart)
      result = (cartData as Cart).shipping_addresses[0].selected_shipping_method
      error.value.save = null
    } catch (err) {
      error.value.save = err
      Logger.error('useShippingProvider/save', err)
      error.value.save = {
        name: 'error',
        message: err.message
      }
      throw err
    } finally {
      loading.value = false
    }

    return result
  }

  async function load ({ customQuery = defaultCustomQuery }: UseShippingProviderLoadParams = {}): Promise<SelectedShippingMethod | null> {
    Logger.debug('useShippingProvider.load')
    let result = null
    try {
      loading.value = true
      if (!cart?.value?.shipping_addresses[0]?.selected_shipping_method) {
        await loadCart({ customQuery })
      }

      result = cart.value?.shipping_addresses[0]?.selected_shipping_method
      error.value.load = null
    } catch (err) {
      error.value.load = err
      Logger.error('useShippingProvider/load', err)
      throw err
    } finally {
      loading.value = false
    }

    return result
  }

  return {
    load,
    save,
    error: error as Readonly<Ref<UseShippingProviderErrors>>,
    loading: loading as any as Readonly<Ref<boolean>>
  }
}

export * from './useShippingProvider'

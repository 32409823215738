import { extend } from 'vee-validate'
import { email, max, min, required } from 'vee-validate/dist/rules'
import { Context } from '@nuxt/types'
import { MagentoAddress } from '~/types/MagentoAddress'
import { isAddressValid } from '~/modules/checkout/aatrium/helpers/isAddressValid'

const MAX_LENGTH_POSTCODE = 5

// eslint-disable-next-line import/no-default-export
export default (ctx: Context): void => {
  const i18n = ctx.app.i18n

  extend('required', {
    ...required,
    message: i18n.t('Please fill this field to continue') as string
  })

  extend('identity', (value: string): string | boolean => {
    if (/^\d{11}$/.test(value)) {
      return true
    }

    return i18n.t('Check your personal identification number') as string
  })

  extend('phone', (value: string): string | boolean => {
    if (/^\+?\d{7,8}$/.test(value)) {
      return true
    }

    return i18n.t('Invalid mobile number format') as string
  })

  extend('vatNumber', (value: string) => {
    if (/^[A-Z]{2}[0-9]{9}$/.test(value)) {
      return true
    }

    return i18n.t('The number should be in the shape of EE123456789') as string
  })

  extend('onlyAlphaNum', (value: string) => {
    if (/^[\w\söÖäÄõÕüÜ]+$/.test(value)) {
      return true
    }

    return i18n.t('Accepts only alphanumeric characters') as string
  })

  extend('registerCode', (value) => {
    if (!/^\d+$/.test(value)) {
      return i18n.t('The field should contain only numbers') as string
    }

    if (value.length !== 8) {
      return i18n.t('Registry code must contain 8 numbers') as string
    }

    return true
  })

  extend('postcode', (value) => {
    if (!/^\d+$/.test(value)) {
      return i18n.t('The field should contain only numbers') as string
    }

    if (value.length !== MAX_LENGTH_POSTCODE) {
      return i18n.tc('Postcode length should be exactly {n} numbers', MAX_LENGTH_POSTCODE)
    }

    return true
  })

  extend('email', {
    ...email,
    message: i18n.t('Please enter a valid e-mail address') as string
  })

  extend('min', {
    ...min,
    message: (_, { length }) => (
      i18n.tc('The field should have at least {length} characters', length, { length })
    )
  })

  extend('max', {
    ...max,
    message: (_, { length }) => (
      i18n.tc('The field must contain no more than {length} characters', length, { length })
    )
  })

  extend('addressMin', {
    ...min,
    message: i18n.t('Address too short') as string
  })

  extend('cityMin', {
    ...min,
    message: i18n.t('City name too short') as string
  })

  extend('postcodeMin', {
    ...min,
    message: (_, { length }) => (
      i18n.tc('Incorrect postcode. The postcode must be a {length}-digit number', length, { length })
    )
  })

  extend('postcodeMax', {
    ...max,
    message: (_, { length }) => (
      i18n.tc('Incorrect postcode. The postcode must be a {length}-digit number', length, { length })
    )
  })

  extend('passwordMin', {
    ...min,
    message: (_, { length }) => (
      i18n.tc('Password must contain of at least {length} characters', length, { length })
    )
  })

  extend('valid-address', {
    validate (value?: MagentoAddress) {
      return !value || isAddressValid(value)
    },
    message: i18n.t('Select a specific address from the list with street and house information') as string
  })
}

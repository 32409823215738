import type { Ref } from '@nuxtjs/composition-api'
import { ref, useContext } from '@nuxtjs/composition-api'
import { CustomerAddress } from '@vue-storefront/magento-api'
import type { UseAddressesErrors, UseAddressesInterface, UseAddressesParamsInput } from './useAddresses'
import { Logger } from '~/helpers/logger'
import { transformUserCreateAddressInput, transformUserUpdateAddressInput } from '~/modules/customer/helpers/userAddressManipulator'
import type { ComposableFunctionArgs } from '~/composables/types'
import { CustomQuery } from '~/types/core'
import { useUiNotification } from '~/composables'
import { useI18n } from '~/helpers/hooks/usei18n'
import { throwGqlError } from '~/helpers/throwGqlError'

/**
 * @public
 *
 * Allows loading and manipulating addresses of the current user. These
 * addresses can be used for both billing and shipping.
 *
 * See the {@link UseAddressesInterface} for a list of methods and values available in this composable.
 */
export function useAddresses (): UseAddressesInterface {
  const error: Ref<UseAddressesErrors> = ref({
    load: null,
    save: null,
    remove: null,
    update: null
  })
  const loading: Ref<boolean> = ref(false)
  const { app } = useContext()
  const context = app.$vsf
  const { send: sendNotification } = useUiNotification()
  const i18n = useI18n()

  async function load (customQuery?: CustomQuery): Promise<CustomerAddress[]> {
    Logger.debug('[Magento] load user addresses')
    let results = null

    try {
      loading.value = true
      const res = await context.$magento.api.getCustomerAddresses(customQuery)
      throwGqlError(res)
      const { data } = res
      results = data?.customer?.addresses ?? []
      Logger.debug('[Magento] load user addresses results:', results)
      error.value.load = null
    } catch (err) {
      error.value.load = err
      Logger.error('Magento] load user addresses error', err)
      throw err
    } finally {
      loading.value = false
    }

    return results
  }

  async function save (params: ComposableFunctionArgs<UseAddressesParamsInput>): Promise<CustomerAddress> {
    Logger.debug('[Magento] save user address:', params.address)
    let results = null

    try {
      loading.value = true
      const res = await context.$magento.api.createCustomerAddress(transformUserCreateAddressInput(params), params?.customQuery ?? null)
      throwGqlError(res)
      const { data } = res
      if (data?.createCustomerAddress !== null) {
        sendNotification({
          id: Symbol('personal-data-updated'),
          type: 'success',
          icon: 'circle-checkmark',
          title: i18n.t('Shipping address has been saved'),
          persist: false,
          timeToLive: 5000
        })
      }
      results = data?.createCustomerAddress ?? {}
      Logger.debug('[Magento] save user address results:', params.address)
      error.value.save = null
    } catch (err) {
      error.value.save = err

      sendNotification({
        id: Symbol('personal-data-updated'),
        type: 'success',
        icon: 'circle-checkmark',
        title: i18n.t(err),
        persist: false,
        timeToLive: 5000
      })

      Logger.error('[Magento] save user address error:', err)
    } finally {
      loading.value = false
    }

    return results
  }

  async function update (params: ComposableFunctionArgs<UseAddressesParamsInput>): Promise<CustomerAddress> {
    Logger.debug('[Magento] update user address:', params.address)
    let results = null

    try {
      loading.value = true
      const res = await context.$magento.api.updateCustomerAddress(transformUserUpdateAddressInput(params), params?.customQuery ?? null)
      throwGqlError(res)
      const { data } = res
      if (data?.updateCustomerAddress !== null) {
        sendNotification({
          id: Symbol('personal-data-updated'),
          type: 'success',
          icon: 'circle-checkmark',
          title: i18n.t('Shipping address has been saved'),
          persist: false,
          timeToLive: 5000
        })
      }
      results = data?.updateCustomerAddress ?? {}
      Logger.debug('[Magento] update user address results:', results)
      error.value.update = null
    } catch (err) {
      error.value.update = err

      sendNotification({
        id: Symbol('personal-data-updated'),
        type: 'success',
        icon: 'circle-checkmark',
        title: i18n.t(err),
        persist: false,
        timeToLive: 5000
      })

      Logger.error('[Magento] update user address error:', err)
    } finally {
      loading.value = false
    }

    return results
  }

  async function remove (params: ComposableFunctionArgs<UseAddressesParamsInput>): Promise<boolean> {
    Logger.debug('[Magento] remove user address:', params.address)
    let results = null

    try {
      loading.value = true
      const res = await context.$magento.api.deleteCustomerAddress(params.address.id, params?.customQuery ?? null)
      throwGqlError(res)
      const { data } = res
      if (data?.deleteCustomerAddress !== null) {
        sendNotification({
          id: Symbol('personal-data-updated'),
          type: 'success',
          icon: 'circle-checkmark',
          title: i18n.t('Shipping address has been removed'),
          persist: false,
          timeToLive: 5000
        })
      }
      results = !!data.deleteCustomerAddress
      Logger.debug('[Magento] remove user address results:', results)
      error.value.remove = null
    } catch (err) {
      error.value.remove = err

      sendNotification({
        id: Symbol('personal-data-updated'),
        type: 'success',
        icon: 'circle-checkmark',
        title: i18n.t(err),
        persist: false,
        timeToLive: 5000
      })

      Logger.error('[Magento] remove user address error:', err)
    } finally {
      loading.value = false
    }

    return results
  }

  async function updateAddressDefaults (id: number, isDefaultShipping: boolean, isDefaultBilling: boolean): Promise<void> {
    await context.$magento.api.updateCustomerAddress({
      addressId: id,
      input: {
        default_shipping: isDefaultShipping,
        default_billing: isDefaultBilling
      }
    })
  }

  return {
    error,
    loading,
    load,
    save,
    update,
    remove,
    updateAddressDefaults
  }
}

export * from './useAddresses'
// eslint-disable-next-line import/no-default-export
export default useAddresses

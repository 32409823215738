<template>
  <component
    :is="link ? 'nuxt-link' : 'button'"
    :to="link"
    :aria-disabled="disabled"
    :class="[
      'base-button',
      { 'disabled': disabled }
    ]"
    v-on="!disabled ? $listeners : {}"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    link: {
      type: [String, Object],
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button {
  display: var(--button-display, flex);
  justify-content: var(--button-justify-content, center);
  align-items: center;

  text-align: var(--button-text-align, center);
  cursor: pointer;

  width: var(--button-width, var(--button-size));
  height: var(--button-height, var(--button-size, 44px));

  background: var(--button-background);
  color: var(--button-color);
  border: var(--button-border);

  padding: var(--button-padding, 12px 24px);

  border-radius: var(--button-border-radius, 22px);
  border-color: var(--button-border-color);

  font-family: var(--button-font-family);
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight, 600);
  line-height: var(--button-line-height);
  letter-spacing: var(--button-letter-spacing);

  position: var(--button-position);
  left: var(--button-left);
  right: var(--button-right);
  top: var(--button-top);
  bottom: var(--button-bottom);

  &.primary {
    --button-background: var(--c-primary);
    --button-color: var(--c-white);

    @include universal-hover {
      --button-background: var(--c-secondary);
      --button-color: var(--c-white);
    }
  }

  &.secondary {
    --button-background: unset;
    --button-border: 1px solid var(--c-grey);

    @include universal-hover {
      --button-color: var(--c-grey);
      --button-border: 1px solid var(--c-grey-1);
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    --button-background: var(--c-grey);
  }

  &.header-action {
    --button-background: var(--c-light);

    @include universal-hover {
      i {
        &:after {
          color: var(--c-primary);
        }
      }
    }
  }

  &.cart-button {
    &_has-items {
      --button-background: var(--c-primary);

      i:after {
        color: var(--c-white);
      }

      @include universal-hover {
        i:after {
          color: var(--c-white);
        }
      }
    }
  }
}
</style>

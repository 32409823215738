import { CustomQuery, UseContextReturn } from '~/types/core'
import type { PlaceOrderOutput } from '~/modules/GraphQL/types'
import { throwGqlError } from '~/helpers/throwGqlError'

export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, customQuery: CustomQuery): Promise<PlaceOrderOutput | null> => {
    const res = await context.app.$vsf.$magento.api.placeOrder({ cart_id: cartId }, customQuery)
    throwGqlError(res)
    return res.data?.placeOrder ?? null
  }
}

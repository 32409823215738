import { readonly, ref, useContext } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { UseCountrySearchInterface } from './useCountrySearch'
import { Logger } from '~/helpers/logger'
import { ComposableFunctionArgs, Countries } from '~/composables/types'
import { Country, Maybe } from '~/modules/GraphQL/types'
import { useCountrySearchStore } from '~/composables/useCountrySearch/useCountrySearchStore'

/**
 * Allows fetching a list of countries or a single country by ID
 *
 * See the {@link UseCountrySearchInterface} for a list of methods and values available in this composable.
 */
export function useCountrySearch (): UseCountrySearchInterface {
  const countryStore = useCountrySearchStore()
  const { countries, countryDetails } = storeToRefs(countryStore)
  const { app } = useContext()
  const loading = ref(false)
  const error = ref({
    search: null,
    load: null
  })

  async function search (params: ComposableFunctionArgs<{ id: string }>): Promise<Maybe<Country>> {
    try {
      if (countryDetails.value[params.id]) {
        return countryDetails.value[params.id]
      }

      loading.value = true

      Logger.debug('[Magento]: Search country information based on', { params })

      const { data } = await app.$vsf.$magento.api.country(params.id, params?.customQuery ?? null)

      Logger.debug('[Result]:', { data })

      if (data?.country) {
        countryStore.$patch((state) => {
          state.countryDetails = {
            ...state.countryDetails,
            [params.id]: data.country
          }
        })
      }

      return data?.country
    } catch (err) {
      error.value.search = err
      Logger.error('useCountrySearch/search', err)
    } finally {
      error.value.search = null
      loading.value = false
    }
  }

  async function load (): Promise<Countries[]> {
    try {
      if (countries.value.length) {
        return countries.value
      }

      loading.value = true

      Logger.debug('[Magento]: Load available countries on store')

      const { data } = await app.$vsf.$magento.api.countries()

      Logger.debug('[Result]:', { data })

      countries.value = data?.countries ?? []
      return countries.value
    } catch (err) {
      error.value.search = err
      Logger.error('useCountrySearch/load', err)
    } finally {
      error.value.search = null
      loading.value = false
    }
  }

  return {
    load,
    search,
    loading: readonly(loading),
    error: readonly(error)
  }
}

export * from './useCountrySearch'
// eslint-disable-next-line import/no-default-export
export default useCountrySearch

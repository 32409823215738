import Vue from 'vue'
import { Swiper as SwiperClass } from 'swiper'
import exporter from 'vue-awesome-swiper/dist/exporter'
// @ts-expect-error
import Pagination from 'swiper/modules/pagination'
// @ts-expect-error
import Autoplay from 'swiper/modules/autoplay'
// @ts-expect-error
import Grid from 'swiper/modules/grid'

SwiperClass.use([Pagination, Autoplay, Grid])

Vue.use(exporter(SwiperClass))

import { reactive, toRefs } from '@nuxtjs/composition-api'
import { BillingDetails, useBilling } from '~/modules/checkout/composables/useBilling'
import { useUser } from '~/modules/customer/composables/useUser'
import { CHECKOUT_STEPS, CheckoutStep } from '~/composables/useCheckoutState/enums/CheckoutStep'
import { CHECKOUT_DUMP_STORAGE_KEY, CHECKOUT_STORAGE_KEY } from '~/composables/useCheckoutState/variables'
import { getCheckoutStateStorage } from '~/composables/useCheckoutState/helpers/getCheckoutStateStorage'
import { UseCheckoutState } from '~/composables/useCheckoutState/interfaces/UseCheckoutState'
import { getInitialCheckoutState } from '~/composables/useCheckoutState/helpers/getInitialCheckoutState'

const state = reactive({
  activeStep: CheckoutStep.contactInfo,
  checkoutData: getInitialCheckoutState(),
  loading: false
})

export function useCheckoutState (): UseCheckoutState {
  const { isAuthenticated, user: currentUser } = useUser()
  const { save: saveBilling } = useBilling()

  // const { save: saveShipping } = useShipping()

  function activateStep (step: CheckoutStep): void {
    if (!process.client) {
      return
    }

    state.activeStep = CHECKOUT_STEPS.includes(step) ? step : CHECKOUT_STEPS[0]
  }

  function resetStep (): void {
    if (!process.client) {
      return
    }

    state.activeStep = CHECKOUT_STEPS[0]
  }

  function syncCheckoutState (): void {
    if (!process.client) {
      return
    }

    state.checkoutData = getCheckoutStateStorage()
  }

  function stepIsSubmitted (step: CheckoutStep): boolean {
    return !!state.checkoutData[step].submitted
  }

  function changeSubmitStatusOfStep (step: CheckoutStep, status: boolean): void {
    state.checkoutData[step].submitted = status
    saveCheckoutStorage()
    syncCheckoutState()
  }

  function saveCheckoutStorage (): void {
    if (!process.client) {
      return
    }

    localStorage.setItem(CHECKOUT_STORAGE_KEY, JSON.stringify(state.checkoutData))
    localStorage.setItem(CHECKOUT_DUMP_STORAGE_KEY, JSON.stringify(state.checkoutData))
  }

  function resetCheckoutStorage (): void {
    if (!process.client) {
      return
    }

    localStorage.setItem(CHECKOUT_STORAGE_KEY, JSON.stringify(getInitialCheckoutState()))
  }

  function updateCheckoutInfo (): void {
    if (!isAuthenticated.value) {
      return
    }
    state.checkoutData.contactInfo.firstName = currentUser.value.firstname
    state.checkoutData.contactInfo.lastName = currentUser.value.lastname
    state.checkoutData.contactInfo.email = currentUser.value.email

    saveCheckoutStorage()
  }

  function sendShippingAddress (params = { reset: false, reloadCart: false }): void {
    // const { reset, reloadCart } = params

    state.loading = true

    // const data = {
    //   // itemsGroups: state.checkoutData.shipping.itemsGroups.map(({ carrier_code, group_id, method_code, location_id, reset_shipping }) => {
    //   //   const item = { carrier_code, group_id, method_code, reset_shipping }
    //   //   return location_id ? { ...item, location_id } : item
    //   // }),
    //   shippingDetails: {
    //     firstname: state.checkoutData.shipping.address.firstName || state.checkoutData.contactInfo.firstName,
    //     lastname: state.checkoutData.shipping.address.lastName || state.checkoutData.contactInfo.lastName,
    //     telephone: state.checkoutData.shipping.address.phoneNumber || state.checkoutData.contactInfo.phoneNumber,
    //     street: state.checkoutData.shipping.address.street || '0',
    //     city: state.checkoutData.shipping.address.city || '0',
    //     region: state.checkoutData.shipping.address.region.region_code || 'EE-37',
    //     region_id: state.checkoutData.shipping.address.region.region_id || 340,
    //     postcode: state.checkoutData.shipping.address.postcode || '10001',
    //     country_code: state.checkoutData.shipping.address.country || 'EE'
    //   },
    //   reset_items_groups: reset
    // }

    // state.checkoutData.shipping.itemsGroups.forEach(pkg => {
    //   if (pkg.location_id && pkg.carrier_code === 'instore') {
    //     data.shippingDetails['pickup_location_code'] = pkg.location_id;
    //   }
    // });

    // await saveShipping(data, reloadCart);

    state.loading = false
  }

  async function sendBillingAddress (billingDetailsData: BillingDetails): Promise<void> {
    state.loading = true
    await saveBilling({ billingDetails: billingDetailsData }).finally(() => {
      state.loading = false
    })
  }

  function restoreFromDump (): void {
    if (!process.client) {
      return
    }

    if (localStorage.getItem(CHECKOUT_DUMP_STORAGE_KEY)) {
      localStorage.setItem(CHECKOUT_STORAGE_KEY, localStorage.getItem(CHECKOUT_DUMP_STORAGE_KEY))
      syncCheckoutState()
    }
  }

  return {
    ...toRefs(state),
    activateStep,
    syncCheckoutState,
    saveCheckoutStorage,
    resetCheckoutStorage,
    updateCheckoutInfo,
    sendShippingAddress,
    sendBillingAddress,
    changeSubmitStatusOfStep,
    resetStep,
    restoreFromDump,
    stepIsSubmitted
  }
}

import type { ApolloQueryResult } from '@apollo/client'
import type { FetchResult } from '@apollo/client/link/core/types'
import { extractGqlError } from './extractGqlError'

export function throwGqlError (
  res: ApolloQueryResult<any> | FetchResult<any> | undefined | null
): void {
  const err = extractGqlError(res)
  if (err) {
    throw new Error(err)
  }
}

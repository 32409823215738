import { readonly, ref, useContext } from '@nuxtjs/composition-api'
import { PaymentMethodParams, UsePaymentProviderErrors, UsePaymentProviderInterface, UsePaymentProviderSaveParams } from './usePaymentProvider'
import { Logger } from '~/helpers/logger'
import { setPaymentMethodOnCartCommand } from '~/modules/checkout/composables/usePaymentProvider/commands/setPaymentMethodOnCartCommand'
import { getAvailablePaymentMethodsCommand } from '~/modules/checkout/composables/usePaymentProvider/commands/getAvailablePaymentMethodsCommand'
import { useCart } from '~/modules/checkout/composables/useCart'
import { reorderPaymentMethods } from '~/modules/checkout/aatrium/helpers/reorderPaymentMethods'

import { CustomQuery } from '~/types/core'
import { AatriumAvailablePaymentMethod, AatriumPaymentGroup, AatriumPaymentGroups } from '~/modules/checkout/aatrium/types'
import { AatriumPaymentGroupMetaConfig } from '~/modules/checkout/aatrium/variables'

/**
 * Allows loading the available payment
 * methods for current cart, and selecting (saving) one of them.
 *
 * See the {@link UsePaymentProviderInterface} for a list of methods and values available in this composable.
 */
export function usePaymentProvider (): UsePaymentProviderInterface {
  const context = useContext()
  const { cart } = useCart()
  const apiState = context.app.$vsf.$magento.config.state
  const loading = ref(false)
  const error = ref<UsePaymentProviderErrors>({
    load: null,
    save: null
  })

  async function save (params: UsePaymentProviderSaveParams): Promise<AatriumAvailablePaymentMethod[]> {
    Logger.debug('usePaymentProvider.save')
    let result = null

    try {
      loading.value = true
      const paymentMethodParams: PaymentMethodParams = {
        cart_id: cart.value.id,
        payment_method: {
          ...params.paymentMethod
        },
        customQuery: params.customQuery
      }

      result = await setPaymentMethodOnCartCommand.execute(context, paymentMethodParams)
      error.value.save = null
    } catch (err) {
      error.value.save = err
      Logger.error('usePaymentProvider/save', err)
      throw err
    } finally {
      loading.value = false
    }

    Logger.debug('[Result]:', { result })
    return result
  }

  async function load (customQuery?: CustomQuery): Promise<AatriumPaymentGroup[] | null> {
    Logger.debug('usePaymentProvider.load')
    let result: AatriumAvailablePaymentMethod[] = null
    customQuery = customQuery || { paymentMethods: 'paymentMethods' }

    try {
      loading.value = true
      result = await getAvailablePaymentMethodsCommand.execute(context, apiState.getCartId(), customQuery)
      error.value.load = null
    } catch (err) {
      error.value.load = err
      Logger.error('usePaymentProvider/load', err)
      throw err
    } finally {
      loading.value = false
    }
    Logger.debug('[Result]:', { result })

    if (result?.length) {
      const reducedResult = result?.reduce<AatriumPaymentGroup[]>((paymentMethodsGroups, paymentMethod) => {
        paymentMethod.group_code = paymentMethod.group_code || AatriumPaymentGroups.other
        let paymentGroup = paymentMethodsGroups.find(g => g.groupCode === paymentMethod.group_code)
        if (!paymentGroup) {
          paymentGroup = {
            groupCode: paymentMethod.group_code,
            label: AatriumPaymentGroupMetaConfig[paymentMethod.group_code]?.label,
            type: AatriumPaymentGroupMetaConfig[paymentMethod.group_code]?.type,
            methods: []
          }
          paymentMethodsGroups.push(paymentGroup)
        }

        paymentMethod.title = paymentMethod.title.replace(/(\s)(€)/g, '€')
        paymentGroup.methods.push(paymentMethod)
        return paymentMethodsGroups
      }, [])

      return reorderPaymentMethods(reducedResult)
    }

    throw new Error('Error during loading payment providers')
  }

  return {
    load,
    save,
    error: readonly(error),
    loading: readonly(loading)
  }
}

export * from './usePaymentProvider'

import { computed, ref, useContext, Ref } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import * as Sentry from '@sentry/browser'
import {
  UseUserChangePasswordParams,
  UseUserErrors,
  UseUserInterface,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams
} from './useUser'
import mask from '~/composables/utils/mask'
import { Logger } from '~/helpers/logger'
import { useCustomerStore } from '~/modules/customer/stores/customer'
import { useCart } from '~/modules/checkout/composables/useCart'
import { generateUserData } from '~/modules/customer/helpers/generateUserData'
import { AatriumCustomer } from '~/modules/customer/types/AatriumCustomer'
import { useUiNotification } from '~/composables'
import { useI18n } from '~/helpers/hooks/usei18n'
import updateCustomerEmailGql from '~/modules/customer/aatrium/graphql/updateCustomerEmail.gql'
import { useLoadingStore } from '~/modules/checkout/aatrium/stores/loadingStore'

/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser (): UseUserInterface {
  const loadingStore = useLoadingStore()
  const { loadingUser: loading } = storeToRefs(loadingStore)
  const customerStore = useCustomerStore()
  const { app, $recaptcha } = useContext()
  const { setCart } = useCart()
  const { send: sendNotification } = useUiNotification()
  const i18n = useI18n()

  const errorsFactory = (): UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null
  })
  const error: Ref = ref(errorsFactory())

  const setUser = (newUser: AatriumCustomer): void => {
    customerStore.user = newUser
    Logger.debug('useUserFactory.setUser', newUser)
  }

  const resetErrorValue = (): void => {
    error.value = errorsFactory()
  }

  const updateCustomerEmail = async (credentials: { email: string }): Promise<void> => {
    const { errors } = await app.context.$vsf.$magento.api.customQuery({
      query: updateCustomerEmailGql,
      queryVariables: credentials
    })

    if (errors) {
      sendNotification({
        id: Symbol('update-customer-email-failed'),
        type: 'danger',
        title: i18n.t(errors[0].message),
        persist: false,
        timeToLive: 5000
      })

      throw errors.map(e => e.message).join(',')
    }
  }

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser }: UseUserUpdateUserParams, customSuccessMessage: string = null): Promise<void> => {
    Logger.debug('[Magento] Update user information', { providedUser })
    resetErrorValue()

    try {
      loading.value = true
      const { email: oldEmail } = customerStore.user
      const { email, ...updateData } = providedUser

      const userData = generateUserData(updateData)

      if (email && email !== oldEmail) {
        await updateCustomerEmail({ email })
      }

      const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(userData, { updateCustomer: 'updateCustomer' })
      Logger.debug('[Result]:', { data })

      if (errors) {
        const allErrorMessages = errors.map(e => e.message).join(',')

        sendNotification({
          id: Symbol('personal-data-updating-failed'),
          type: 'danger',
          title: i18n.t(errors[0].message),
          persist: false,
          timeToLive: 5000
        })

        Logger.error(allErrorMessages)
        error.value.updateUser = allErrorMessages
      }

      if (data?.updateCustomerV2?.customer) {
        customerStore.user = data.updateCustomerV2.customer || {}

        sendNotification({
          id: Symbol('personal-data-updated'),
          type: 'success',
          icon: 'circle-checkmark',
          title: customSuccessMessage ? i18n.t(customSuccessMessage) : i18n.t('Personal data has been updated'),
          persist: false,
          timeToLive: 5000
        })
      }

      error.value.updateUser = null
    } catch (err) {
      error.value.updateUser = err
      Logger.error('useUser/updateUser', err)
    } finally {
      loading.value = false
    }
  }

  const logout = async ({ customQuery = {} }: UseUserLogoutParams = {}): Promise<void> => {
    Logger.debug('[Magento] useUserFactory.logout')
    resetErrorValue()

    try {
      const apiState = app.context.$vsf.$magento.config.state

      await app.context.$vsf.$magento.api.revokeCustomerToken(customQuery)

      apiState.removeCustomerToken()
      apiState.removeCartId()
      setCart(null)
      customerStore.setIsLoggedIn(false)
      error.value.logout = null
      customerStore.user = null
    } catch (err) {
      error.value.logout = err
      Logger.error('useUser/logout', err)
    }
  }

  const load = async (): Promise<AatriumCustomer | null> => {
    Logger.debug('[Magento] useUser.load')
    resetErrorValue()

    try {
      loading.value = true
      const apiState = app.context.$vsf.$magento.config.state

      if (!apiState.getCustomerToken()) {
        return null
      }
      try {
        const { data } = await app.context.$vsf.$magento.api.customer({ customer: 'customer' })

        Logger.debug('[Result]:', { data })

        customerStore.user = data?.customer ?? {}
        Sentry.setUser({ email: customerStore.user?.email ?? '' })
      } catch {
        await logout()
      }
      error.value.load = null
    } catch (err) {
      error.value.load = err
      Logger.error('useUser/load', err)
    } finally {
      loading.value = false
    }

    return customerStore.user
  }

  // eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
  const login = async ({ user: providedUser, customQuery }: UseUserLoginParams): Promise<void> => {
    Logger.debug('[Magento] useUser.login', providedUser)
    resetErrorValue()

    try {
      loading.value = true

      const { data, errors } = await app.$vsf.$magento.api.generateCustomerToken(
        {
          email: providedUser.email,
          password: providedUser.password,
          recaptchaToken: providedUser.recaptchaToken
        },
        customQuery || {}
      )
      Logger.debug('[Result]:', { data })

      if (errors) {
        const joinedErrors = errors.map(e => e.message).join(',')
        Logger.error(joinedErrors)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors)
      }

      await loginByToken(data.generateCustomerToken.token)
      await mergeCart()
      error.value.login = null
    } catch (err) {
      error.value.login = err
      Logger.error('useUser/login', err)
    } finally {
      loading.value = false
    }
  }

  // eslint-disable-next-line consistent-return
  const register = async ({ user: providedUser, customQuery }: UseUserRegisterParams): Promise<void> => {
    Logger.debug('[Magento] useUser.register', providedUser)
    resetErrorValue()

    try {
      loading.value = true

      const {
        email,
        password,
        recaptchaToken,
        ...baseData
      } = generateUserData(providedUser)

      const { data, errors } = await app.$vsf.$magento.api.createCustomer(
        {
          email,
          password,
          recaptchaToken,
          ...baseData
        },
        customQuery || {}
      )

      Logger.debug('[Result]:', { data })

      if (errors) {
        const joinedErrors = errors.map(e => e.message).join(',')
        Logger.error(joinedErrors)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        throw new Error(joinedErrors)
      }
      error.value.register = null
      let loginRecaptchaToken = ''
      if ($recaptcha && recaptchaToken) {
        loginRecaptchaToken = await $recaptcha.getResponse()
      }

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { customer: { customer_create_account_confirm } } = app.context.$vsf.$magento.config

      if (customer_create_account_confirm) {
        return await new Promise((resolve) => {
          sendNotification({
            id: Symbol('registration_confirmation'),
            message: app.i18n.t('You must confirm your account. Please check your email for the confirmation link.') as string,
            persist: true,
            title: 'Registration confirmation',
            type: 'success',
            icon: 'check'
          })

          resolve()
        })
      }
      await login({ user: { email, password, recaptchaToken: loginRecaptchaToken }, customQuery: {} })
    } catch (err) {
      error.value.register = err
      Logger.error('useUser/register', err)
    } finally {
      loading.value = false
    }
  }

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams): Promise<void> => {
    Logger.debug('[Magento] useUser.changePassword', { currentPassword: mask(params.current), newPassword: mask(params.new) })
    resetErrorValue()

    try {
      loading.value = true

      const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword({
        currentUser: customerStore.user,
        currentPassword: params.current,
        newPassword: params.new
      }, params.customQuery)

      let joinedErrors = null

      if (errors) {
        joinedErrors = errors.map(e => e.message).join(',')
        Logger.error(joinedErrors)

        sendNotification({
          id: Symbol('password-changed-error'),
          type: 'danger',
          title: i18n.t(errors[0].message),
          persist: false,
          timeToLive: 5000
        })
      }

      if (data?.changeCustomerPassword !== null) {
        sendNotification({
          id: Symbol('password-changed-success'),
          type: 'success',
          icon: 'circle-checkmark',
          title: i18n.t('Password changed'),
          persist: false,
          timeToLive: 5000
        })
      }

      Logger.debug('[Result] ', { data })

      error.value.changePassword = joinedErrors
    } catch (err) {
      error.value.changePassword = err
      Logger.error('useUser/changePassword', err)
    } finally {
      loading.value = false
    }
  }

  const loginByToken = async (token: string): Promise<void> => {
    Logger.debug('[Magento] useUser.loginByToken')
    customerStore.setIsLoggedIn(true)
    const apiState = app.context.$vsf.$magento.config.state
    await apiState.setCustomerToken(token)
    error.value.login = null
  }

  // merge existing cart with customer cart
  const mergeCart = async (): Promise<void> => {
    Logger.debug('[Magento] useUser.mergeCart')
    const apiState = app.context.$vsf.$magento.config.state

    const currentCartId = apiState.getCartId()
    const cart = await app.context.$vsf.$magento.api.customerCart()
    const newCartId = cart.data.customerCart.id

    if (newCartId && currentCartId && currentCartId !== newCartId) {
      const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
        {
          sourceCartId: currentCartId,
          destinationCartId: newCartId
        }
      )

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setCart(dataMergeCart.mergeCarts)
      apiState.setCartId(dataMergeCart.mergeCarts.id)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setCart(cart.data.customerCart)
    }
  }

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    load,
    loginByToken,
    mergeCart,
    loading: loading as any as Readonly<Ref<boolean>>,
    error: error as Readonly<Ref<UseUserErrors>>,
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn)
  }
}

// eslint-disable-next-line import/no-default-export
export default useUser
export * from './useUser'

import { computed, ComputedRef } from '@nuxtjs/composition-api'
import { storeToRefs } from 'pinia'
import { CartItemInterface, useCart } from '~/composables'
import { UseAssemblyProducts } from '~/composables/useAssemblyProductsList/interfaces/UseAssemblyProducts'
import { useAssemblyProductsStore } from '~/composables/useAssemblyProductsList/store/useAssemblyProductsStore'
import { getItems } from '~/modules/checkout/getters/cartGetters'

export function useAssemblyProductsList (): UseAssemblyProducts {
  const { cart, removeItem } = useCart()
  const assemblyStore = useAssemblyProductsStore()
  const { load } = assemblyStore
  const { products, loading, error } = storeToRefs(assemblyStore)

  const addedProductsInCart: ComputedRef<CartItemInterface[]> = computed((): CartItemInterface[] => {
    return getItems(cart.value).filter((i: CartItemInterface) => skusOfProducts.value.includes(i.product.sku)) || []
  })

  async function removeAllAssemblyProducts (): Promise<void> {
    for (const product of addedProductsInCart.value) {
      await removeItem({ product })
    }
  }

  const skusOfProducts = computed(() => products.value?.map(product => product.sku))

  return {
    addedProductsInCart,
    products,
    loading,
    error,
    load,
    removeAllAssemblyProducts
  }
}

import { PaymentMethodParams } from '../usePaymentProvider'
import { UseContextReturn } from '~/types/core'
import { AatriumAvailablePaymentMethod } from '~/modules/checkout/aatrium/types'
import { throwGqlError } from '~/helpers/throwGqlError'

export const setPaymentMethodOnCartCommand = {
  execute: async (context: UseContextReturn, params: PaymentMethodParams): Promise<AatriumAvailablePaymentMethod[]> => {
    const res = await context.app.$vsf.$magento.api.setPaymentMethodOnCart(params, params?.customQuery ?? null)
    const { data } = res
    throwGqlError(res)
    return data?.setPaymentMethodOnCart?.cart.available_payment_methods ?? []
  }
}

import { ApolloQueryResult } from '@apollo/client'
import { FetchResult } from '@apollo/client/link/core/types'

export function extractGqlError (
  res: ApolloQueryResult<any> | FetchResult<any> | undefined | null
): string | undefined {
  if (!res) {
    return 'Response is not defined'
  }

  if (res?.errors?.length) {
    return res?.errors[0].message
  }
  return undefined
}

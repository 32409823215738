
import { computed } from '@nuxtjs/composition-api'
import HeaderButton from './HeaderButton.vue'
import { useUiState } from '~/composables'
import { useCart } from '~/modules/checkout/composables/useCart'

export default {
  name: 'CartButton',
  components: { HeaderButton },
  setup () {
    const { toggleCartSidebar } = useUiState()
    const { cart } = useCart()
    const totalItems = computed(() => cart.value?.total_quantity ?? 0)
    const hasItems = computed(() => totalItems.value > 0)

    return {
      cart,
      totalItems,
      hasItems,
      toggleCartSidebar
    }
  }
}

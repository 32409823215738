import { AvailableShippingMethod } from '~/modules/GraphQL/types'
import { VsfContext } from '~/composables/context'
import { ComposableFunctionArgs } from '~/composables'
import { throwGqlError } from '~/helpers/throwGqlError'

export const getCustomerShippingMethodsCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<{}>): Promise<AvailableShippingMethod[]> => {
    const res = await context.$magento.api.getAvailableCustomerShippingMethods(params?.customQuery ?? null)
    throwGqlError(res)
    const {
      data: { customerCart: { shipping_addresses: shippingAddresses } }
    } = res
    return shippingAddresses[0]?.available_shipping_methods ?? []
  }
}
